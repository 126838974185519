import { GP_ACCESS_TOKEN_COOKIE } from '~/plugins/auth/constants';

export default () => {
  const oidcStore = useOidcStore();
  const accessTokenCookie = useCookie(GP_ACCESS_TOKEN_COOKIE);

  return computed<string | null>(() => {
    if (accessTokenCookie.value != null) {
      return accessTokenCookie.value;
    }

    if (oidcStore.accessToken != null) {
      return oidcStore.accessToken;
    }

    return null;
  });
};
